import { Input } from '@mui/material'
import React from 'react'
import {
  CartesianGrid,
  Legend,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Tooltip,
} from 'recharts'

const DashboardUsersChart = ({ usersChart, setChosenMonth, setChosenYear }) => {
 
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <ResponsiveContainer height={130}>
        <LineChart width={'auto'} height={'auto'}>
          <XAxis dataKey='_id' />
          <YAxis />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip />
          <Legend />
          <Line
            data={usersChart}
            type='monotone'
            dataKey={'Users'}
            stroke={'blue'}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <div>
        <Input
          type='date'
          onChange={(e) => {
            setChosenMonth(new Date(e.target.value).getMonth())
            setChosenYear(new Date(e.target.value).getFullYear())
          }}
        />
      </div>
    </div>
  )
}

export default DashboardUsersChart
