import { useEffect, useRef, useState } from 'react'
import './App.css'
import AdminDashboard from './components/AdminDashboard'
import { io } from 'socket.io-client'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import cookies from 'js-cookie'

function App() {
  const socket = useRef()
  const USER_DECRYPT_SECRET_KEY = String(process.env.USER_DECRYPT_SECRET_KEY)
  const [user, setUser] = useState(null)

  const encryptedUser = cookies.get('user')

  useEffect(() => {
    try {
      if (encryptedUser) {
        const decryptedData = CryptoJS.AES.decrypt(
          encryptedUser,
          USER_DECRYPT_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
        if (decryptedData) {
          setUser(JSON.parse(decryptedData))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [cookies.user])

  useEffect(() => {
    socket.current = io('https://hutupia-socket.onrender.com')
  }, [user?._id])

  axios.defaults.baseURL = 'https://hutupia-backend.onrender.com/api/v1'

  const lang = cookies.get('i18next')

  const isDarkModeActive = true
  const props = {
    socket,
    isDarkModeActive,
    user,
    lang,
  }

  return (
    <div className='App'>
      <AdminDashboard {...props} />
    </div>
  )
}

export default App
