import React, { useState, useEffect } from 'react'
import axios from 'axios'
import withStyles from '@mui/styles/withStyles'
import DetailsGridModel from './AdminDashboardComps/DetailsGridModel'
import DashboardUsersChart from './AdminDashboardComps/DashboardUsersChart'

const styles = () => ({
  dashBoardContainer: {
    margin: 10,
    padding: 10,
    direction: 'ltr',
  },
  dashboardWrapper: {},
})

const AdminDashboard = ({ classes, socket, user }) => {
  const [usersCount, setUsersCount] = useState()
  const [postsCount, setPostsCount] = useState()
  const [projectsCount, setProjectsCount] = useState()
  const [usersChart, setUsersChart] = useState()
  const currentYear = new Date().getUTCFullYear()
  const currentMonth = new Date().getUTCMonth()
  const [chosenYear, setChosenYear] = useState(currentYear)
  const [chosenMonth, setChosenMonth] = useState(currentMonth)
  const [onlineUsers, setOnlineUsers] = useState(null)

  // ISSUE: i cannot access the local storage data of hutupia.com from this local domain unless , may be it will be possible whent he sunbdomain is activted as this will be the same parent domain and it might work then

  useEffect(() => {
    const fetchUsersCount = async () => {
      try {
        const res = await axios.get(
          `/dashboard/numbers/?year=${chosenYear}&month=${chosenMonth}`
        )
        setUsersCount(res.data.usersCount)
        setPostsCount(res.data.postsCount)
        setProjectsCount(res.data.projectsCount)
        setUsersChart(res.data.usersChart)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUsersCount()
  }, [chosenYear, chosenMonth])

  useEffect(() => {
    socket?.current?.on('getUsers', (users) => {
      setOnlineUsers(users?.length)
    })
  })

  if (user?.isAdmin) {
    return (
      <article className={classes.dashBoardContainer}>
        <section className={classes.dashboardWrapper}>
          <DashboardUsersChart
            usersChart={usersChart}
            setChosenMonth={setChosenMonth}
            setChosenYear={setChosenYear}
          />
          <DetailsGridModel
            infoArray={[
              {
                title: 'Users',
                titleValue: usersCount,
              },
              {
                title: 'Active Users',
                titleValue: onlineUsers,
              },
              {
                title: 'Projects',
                titleValue: projectsCount,
              },
              {
                title: 'Posts',
                titleValue: postsCount,
              },
            ]}
          />
          <br />
          Profiles Managemnent:
          <br />
          profiles will be searchable, and i will be able to:
          <br />
          1- block the profile
          <br />
          2- verify profile by their ids or unverify them
          <br />
          3-
        </section>
      </article>
    )
  } else {
    return (
      <div>
        <span>Please login to access'</span>
        <button>
          <a href='https://hutupia.com/login'> Login</a>
        </button>
      </div>
    )
  }
}

const WrappedAdminDashboard = withStyles(styles)(AdminDashboard)

export default WrappedAdminDashboard
